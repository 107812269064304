<template>
  <div class="input_arr" flex row>
    <el-input
      class="arr_input"
      placeholder="请输入作者名称或者书名进行搜索"
      v-model="val"
      @keyup.enter.native="key_up"
      clearable
      v-if="!rout"
    >
    </el-input>
    <!-- 仅在搜索界面搜索框自动聚焦 -->
    <el-input
      class="arr_input"
      placeholder="请输入作者名称或者书名进行搜索"
      v-model="val"
      @keyup.enter.native="key_up"
      clearable
      v-if="rout"
      v-focus
    >
    </el-input>
    <i class="el-icon-search seach" @click="seach"></i>
  </div>
</template>

<script>
import Bus from "@/Bus/Bus.js";
export default {
  name: "lookInput",
  props: {
    text: {
      type: String,
    },
    is_serach: {
      type: Boolean,
    },
  },
  data() {
    return {
      val: sessionStorage.getItem("search_val") || "",
      // <!-- 仅在搜索界面搜索框自动聚焦 -->
      rout: this.$route.fullPath === "/search" ? true : false,
    };
  },
  computed: {},
  created() {},
  methods: {
    // 点击搜索按钮
    seach() {
      this.$emit("seach");
      this.to_search();
    },
    // enter键
    key_up(e) {
      if (e.keyCode === 13) {
        this.seach();
      }
    },
    // 点击进入搜索界面
    to_search() {
      this.$router.push("/search");
      // document.querySelector('input').focus();
    },
  },
  watch: {
    val() {
      sessionStorage.setItem("search_val", this.val);
      Bus.$emit("val", this.val);
    },
    text() {
      this.seach();
    },
  },
  mounted() {},
  // 自定义v-focus方法
  directives: {
    focus: {
      inserted(el) {
        el.children[0].focus();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.input_arr {
  flex-wrap: nowrap;
  width: 485px;
  height: 43px;
  line-height: 43px;
  position: relative;
}
/deep/.el-input__inner {
  height: 43px !important;
  line-height: 43px !important;
  border-radius: 10px !important;
  padding-right: 88px !important;
  padding-left: 42px !important;
  border: none !important;
  color: #999999;
  background: #eeeeee;
}
.seach {
  width: 88px;
  height: 100%;
  box-sizing: border-box;
  border: 0px solid #dcdfe6;
  border-left: 0 solid #dcdfe6;
  background: #409eff;
  color: #ffffff;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
  position: absolute;
  right: 0px;
  padding: 7px 10px;
  top: 0px;
}
</style>