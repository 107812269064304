<template>
    <div class="top_header container">
        <img
            src="../../assets/images/logo_1.png"
            alt=""
            v-if="nav_type"
            @click="to_home"
        />
        <span v-else></span>
        <div class="head_text" v-if="nav_type">
            <img src="../../assets/images/yuan.png" alt=""/> 鲸云轻小说
        </div>
        <lookInput
            class="seach_box"
            @seach="seach"
            :text="val"
            v-if="input_hide"
        ></lookInput>
        <div class="login_arr" v-if="!has_token">
            <span class="arr_item log_box" @click="login()">登录</span>
            <span
                class="arr_item reg_box"
                @click="$router.push({ path: '/register' })"
            >注册</span
            >
        </div>
        <div class="login_arr login_in" v-if="has_token">
            <!-- <span @click="go_shelf()">我的书架</span> -->
            <div @click="go_shelf()" class="book_shelf">
                <img src="../../assets/images/bookshelf.png" alt=""/>
                <span>书架</span>
            </div>
            <div class="info_box">
                <img
                    :src="person_info.avatar"
                    alt=""
                    class="avatar"
                    :onerror="errorImg01"
                />
                <!-- person_info.nickname -->
                <p ellipsis @click="$router.push({ path: '/mine_center' })">
                    {{ person_info.nickname }}
                </p>
                <ul>
                    <li @click="goToPerson()">
                        <img src="../../assets/images/personal.png" alt=""/><span
                    >个人中心</span
                    >
                    </li>
                    <li @click="goToCommon()">
                        <img src="../../assets/images/news.png" alt=""/><span
                    >我的书评</span
                    >
                    </li>
                    <li @click="goToRecharge()">
                        <img src="../../assets/images/diamond.png" alt=""/><span
                    >会员中心</span
                    >
                    </li>
                    <li>
                        <img src="../../assets/images/email.png" alt=""/><span
                    >消息中心</span
                    >
                    </li>
                    <li @click="goToWriter()" v-if="is_author">
                        <img src="../../assets/images/writer.png" alt=""/><span
                    >作家中心</span
                    >
                    </li>
                    <li class="list_li" @click="out_login()"><span>退出登录</span></li>
                </ul>
            </div>
        </div>
        <div class="login_box" v-if="login_type">
            <div class="login_content" flex column>
                <div class="login_top" flex center>
                    <div class="login_title">
                        {{
                            wxParams.is_need_phone ? "微信绑定手机号" : "鲸云阅读 账号登录"
                        }}
                    </div>
                    <i class="el-icon-close close" @click="close()"></i>
                </div>
                <el-form
                    ref="form"
                    v-if="!isWxLogin"
                    class="login_from"
                    :rules="rules"
                    :model="form"
                    label-width="0px"
                >
                    <el-form-item prop="account">
                        <el-input
                            placeholder="手机号"
                            v-if="!isWxLogin"
                            v-model.number="form.account"
                        >
                            <i slot="prefix" class="el-input__icon iconfont icon-shouji"></i>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="password" v-if="!isWxLogin && !code_pass">
                        <el-input
                            placeholder="密码"
                            type="password"
                            @keyup.enter.native="submitForm()"
                            v-model="form.password"
                        >
                            <i slot="prefix" class="el-input__icon iconfont icon-mima"></i>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="code" v-if="!isWxLogin && code_pass">
                        <div class="code_num">
                            <el-input
                                placeholder="验证码"
                                type="code"
                                @keyup.enter.native="submitForm()"
                                v-model.number="form.code"
                            >
                                <i
                                    slot="prefix"
                                    class="el-input__icon iconfont icon-yanzhengma3"
                                ></i>
                            </el-input>
                            <el-button @click="avoid_code()" :disabled="had_code"
                            ><span v-if="set_time">{{ time + "s" }}</span
                            >{{ code_text }}
                            </el-button>
                        </div>
                    </el-form-item>
                </el-form>
                <div v-show="isWxLogin" class="wx-code" id="wx-code"></div>
                <div v-if="!isWxLogin" class="automatic" flex between>
                    <el-checkbox v-model="checked" v-show="!code_pass"
                    >记住密码(公共场合勿选)
                    </el-checkbox>
                    <div></div>
                    <span
                        class="forget_key"
                        v-if="!wxParams.is_need_phone"
                        @click="$router.push({ path: '/forget_pas' })"
                    >忘记密码?</span
                    >
                </div>
                <div class="log_btn" v-loading="loading" v-if="!isWxLogin">
                    <el-button class="btn_it" type="primary" @click="submitForm()"
                    >{{ wxParams.is_need_phone ? "确认绑定" : btnText }}
                    </el-button>
                </div>
                <div class="wx-code-tips" v-else>
                    <i class="icon iconfont icon-wechat"></i>
                    微信扫码登录
                </div>
                <div class="phone_edictor" flex between v-if="!wxParams.is_need_phone">
          <span
              @click="
              code_pass = true;
              isWxLogin = false;
            "
              v-if="isWxLogin || !code_pass"
          >手机验证码登录</span
          >
                    <div v-if="isWxLogin || !code_pass">|</div>
                    <span
                        @click="
              code_pass = false;
              isWxLogin = false;
            "
                        v-if="isWxLogin || code_pass"
                    >手机密码登录</span
                    >
                    <div v-if="isWxLogin || code_pass">|</div>
                    <span @click="wxLoginAction" v-if="!isWxLogin" class="wxIconCon"
                    ><img
                        class="wxIcon"
                        src="./../../assets/images/index/weixin.png"
                        alt=""
                    /></span>
                    <div v-if="!isWxLogin">|</div>
                    <span @click="$router.push({ path: '/register' })">免费注册 ></span>
                </div>
                <div class="phone_edictor" flex center v-if="wxParams.is_need_phone">
                    <span @click="cancelBind">取消微信登录</span>
                </div>
                <div class="footer">
                    登录即代表同意
                    <span @click="go_serve(1)">《用户服务协议》</span>
                    和
                    <span @click="go_serve(2)">《隐私政策》</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import lookInput from "../input/look-input";
import {
    login,
    send_captcha_login,
    captcha_login,
    bindPhone,
    new_send_captcha,
} from "@/https/api.js";
import {Moneyinfo} from "@/https/mine_center.js";
import {mapGetters} from "vuex";

export default {
    name: "topHeader",
    components: {
        lookInput,
    },
    // inject:['is_search'],
    props: {
        val: {
            type: String,
        },
        input_hide: {
            type: Boolean,
            default: true,
        },
        nav_type: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            login_type: false,
            is_author: true,
            isWxLogin: false,
            loading: false,
            checked: false,
            time: 60,
            had_code: false,
            code_pass: false,
            btnText: "登录",
            code_text: "获取验证码",
            errorImg01:
                'this.src="' +
                require("../../../public/static/default_header.png") +
                '"',
            form: {
                account: "",
                password: "",
                code: "",
            },
            rules: {
                account: [
                    {
                        required: true,
                        type: "number",
                        message: "请输入手机号",
                        trigger: "blur",
                    },
                ],
                password: [{required: true, message: "请输入密码", trigger: "blur"}],
                code: [
                    {
                        required: true,
                        type: "number",
                        message: "请输入验证码",
                        trigger: "blur",
                    },
                ],
            },
            has_token: "",
            person_info: {},
            time_init: null,
            set_time: false,
            wxParams: {},
        };
    },
    created() {
        let token = localStorage.getItem("api_token");
        // let token = this.getCookie("api_token")
        if (token) {
            this.has_token = token;
            // this.$store.commit("set_token",token)
            this.get_mine_infomation();
        }
        this.have_info();
    },
    mounted() {
        this.$store.commit("set_login_type", false);
        this.wxLogin();
    },
    watch: {
        aviod_login_type(n, v) {
            this.login_type = n;
        },
        code_pass(n, v) {
            if (!n) {
                // this.clear_time();
            }
        },
        aviod_token_type(n, v) {
            this.login_type = n;
            if (n === "" || !n) {
                this.out_login();
            }
        },
    },
    computed: {
        ...mapGetters(["aviod_login_type", "aviod_token_type"]),
    },
    beforeDestroy() {
        this.clear_time();
    },
    methods: {
        //微信回调登录判断
        wxLogin() {
            let key = this.$route.query.key;
            let is_need_phone =
                this.$route.query.is_need_phone &&
                Number(this.$route.query.is_need_phone);

            this.wxParams = {
                is_need_phone,
                key,
            };

            if (is_need_phone == 1) {
                this.login_type = true;
                this.code_pass = true;
                this.isWxLogin = false;
            } else if (is_need_phone == 0) {
                this.$store.commit("set_token", encodeURIComponent(key));
                localStorage.setItem("api_token", encodeURIComponent(key));
                this.cancelBind();
            }
        },
        cancelBind() {
            setTimeout(() => {
                location.href = location.origin;
            });
        },
        async bindPhoneAction() {
            if (!this.form.account) {
                this.$message.warning("手机号不能为空");
            } else if (!this.form.code) {
                this.$message.warning("验证码不能为空");
            } else {
                this.loading = true;
                let res = await bindPhone({
                    phone: this.form.account,
                    captcha: this.form.code,
                    key: this.wxParams.key,
                    type: 4,
                });
                this.loading = false;
                if (res.code === 0) {
                    this.$store.commit("set_token", res.data);
                    localStorage.setItem("api_token", res.data);
                    this.cancelBind();
                }
            }
        },
        //获取缓存判断是否 有记住登录
        have_info() {
            let info = localStorage.getItem("person_info");
            if (info) {
                this.form = JSON.parse(info);
                if (JSON.parse(info).account) {
                    this.checked = true;
                }
            }
        },
        to_home() {
            // 返回首页
            this.$router.push({
                path: "/",
            });
            // const { href } = this.$router.resolve({
            //   path: `/`,
            // });
            // window.open(href, "_blank");
        },
        // 搜索框
        seach() {
            this.$emit("search");
        },

        //点击弹出登录框
        login() {
            this.login_type = true;
            this.$store.commit("set_login_type", true);
            this.have_info();
        },

        wxLoginAction() {
            this.isWxLogin = true;

            let baseURL = "";
            if (
                location.href.includes("localhost") ||
                location.href.includes("jytest_pc")
            ) {
                baseURL = "http://jytestapi.qxsclass.com/"; //测试
            } else {
                baseURL = "https://jyapi.jyacg.com/"; //正式服
            }
            setTimeout(function () {
                const WxLogin = window.WxLogin;
                let params = {
                    id: "wx-code",
                    self_redirect: false,
                    appid: "wx92b9143faa41212f",
                    scope: "snsapi_login",
                    redirect_uri: encodeURIComponent(
                        baseURL + "api/common/pcWechatQrCallback"
                    ),
                    state: location.origin,
                };
                new WxLogin(params);
            }, 100);
        },

        //退出登录
        out_login() {
            Object.assign(this.$data, this.$options.data());
            sessionStorage.clear();
            localStorage.removeItem("api_token");
            // this.delCookie("api_token")
            this.$router.push("/index");
            this.$message.success("已退出登录");
        },

        //关闭 登录框
        close() {
            // sessionStorage.setItem("menu_id", 1);
            this.$store.commit("set_login_type", false);
            this.login_type = false;
            this.isWxLogin = false;
            this.wxParams = {};
            this.form = this.$options.data().form;
            this.clear_time();
            // this.$router.push('/index')
        },

        //确认密码/验证码登录
        async submitForm() {
            if (this.wxParams.is_need_phone) {
                this.bindPhoneAction();
                return;
            }
            let props = {
                phone: this.form.account,
            };
            !this.code_pass
                ? (props.password = this.form.password)
                : (props.captcha = this.form.code);
            // 普通登录
            if (!this.code_pass) {
                this.loading = true;
                this.btnText = "登录...";
                await login(props)
                    .then((res) => {
                        if (res.code === 0) {
                            this.$store.commit("set_token", res.data);
                            // this.setCookie("api_token", res.data)
                            localStorage.setItem("api_token", res.data);
                            this.get_mine_info();
                            if (this.checked && !this.code_pass) {
                                localStorage.setItem("person_info", JSON.stringify(this.form));
                            } else {
                                localStorage.setItem("person_info", "");
                            }
                            this.login_type = false;
                        } else {
                            this.$message.warning(res.msg);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                        this.btnText = "登录";
                    });
            } else {
                // 验证码登录
                this.loading = true;
                this.btnText = "登录...";
                if (props.captcha) {
                    await captcha_login(props)
                        .then((res) => {
                            if (res.code === 0) {
                                localStorage.setItem("api_token", res.data);
                                this.get_mine_info();
                                this.login_type = false;
                            } else if (props.captcha === "") {
                                this.$message.warning("验证码不能为空");
                            } else {
                                this.$message.warning(res.msg);
                            }
                        })
                        .finally(() => {
                            this.loading = false;
                            this.btnText = "登录";
                        });
                } else {
                    this.loading = false;
                    this.btnText = "登录";
                    this.$message.warning("请填写验证码");
                }
            }
        },

        // 获取个人信息并刷新
        get_mine_info() {
            Moneyinfo().then((res) => {
                this.is_author = res.data.is_author;
                if (res.code === 0) {
                    this.person_info = res.data;
                    sessionStorage.setItem("person_info", JSON.stringify(res.data));
                    // this.$router.go(0);
                    window.location.reload();
                }
            });
        },
        // 获取个人信息
        get_mine_infomation() {
            Moneyinfo().then((res) => {
                if (res.code === 0) {
                    this.person_info = res.data;
                    this.is_author = res.data && res.data.is_author;
                    this.$store.commit("set_money_info", res.data);
                    sessionStorage.setItem("person_info", JSON.stringify(res.data));
                }
            });
        },
        // 去我的书架
        go_shelf() {
            let mine_menu = {
                id: 2,
                link: "/mine_shelf",
                name: "我的书架",
                icon: "icon-wodeshujia",
            };
            this.$store.commit("set_mine_menu", mine_menu);
            this.$router.push({
                path: "/mine_shelf",
            });
        },
        //去个人中心
        goToPerson() {
            let mine_menu = {
                id: 1,
                link: "/mine_center",
                name: "个人中心",
            };
            this.$store.commit("set_mine_menu", mine_menu);
            this.$router.push({
                path: "/mine_center",
            });
        },
        //去我的评论
        goToCommon() {
            let mine_menu = {
                id: 5,
                link: "/mine_common",
                name: "我的评论",
            };
            this.$store.commit("set_mine_menu", mine_menu);
            this.$router.push({
                path: "/mine_common",
            });
        },
        //会员中心
        goToRecharge() {
            let mine_menu = {
                id: 7,
                link: "/recharge",
                name: "充值中心",
            };
            this.$store.commit("set_mine_menu", mine_menu);
            this.$router.push({
                path: "/recharge",
            });
        },
        //作家中心
        goToWriter() {
            let mine_menu = {
                id: 3,
                link: "/works_manage",
                name: "作家中心",
            };
            this.$store.commit("set_mine_menu", mine_menu);
            this.$router.push({
                path: "/works_manage",
            });
        },
        // 获取验证码
        avoid_code() {
            let params = {
                phone: this.form.account,
                type: 1,
            };
            if (this.form.account) {
                (this.wxParams.is_need_phone
                        ? new_send_captcha(params)
                        : send_captcha_login(params)
                ).then((res) => {
                    if (res.code === 0) {
                        let that = this;
                        this.$message.success("发送成功");
                        this.code_text = "重新获取验证码";
                        this.had_code = true;
                        this.set_time = true;
                        this.time_init = setInterval(() => {
                            if (that.time >= 1) {
                                that.time -= 1;
                            } else {
                                that.time = 60;
                                that.had_code = false;
                                that.set_time = true;
                                that.clear_time();
                            }
                        }, 1000);
                    } else {
                        this.$message.warning(res.msg);
                    }
                });
            } else {
                this.$message.warning("请先输入手机号");
            }
        },
        // 清除验证码时间现在定时器
        clear_time() {
            if (this.time_init) {
                clearInterval(this.time_init);
                this.time_init = null;
                this.time = 60;
                this.set_time = false;
                this.code_text = "获取验证码";
                this.had_code = false;
            }
        },
        go_serve(num) {
            let net = num === 1 ? "/service" : "/privacy";
            const {href} = this.$router.resolve({
                path: `${net}`,
            });
            window.open(href, "_blank");
        },
    },
};
</script>
<style lang="scss" scoped>
@import "../../utils/tool.scss";
// @include flex-center();
li {
    list-style: none;
}

.top_header {
    background-color: #fff;
    height: 109px;
    padding: 0;
    // position: relative;
    z-index: 300;
    position: relative;
    // display: flex;
    // justify-content: space-between;
    align-items: center;

    > img {
        width: 97px;
        height: 50px;
        cursor: pointer;
        float: left;
        // border: 1px solid #ffffff;
        // box-shadow: 5px 5px 8px #eeeeee;
    }

    .head_text {
        height: 56px;
        line-height: 56px;
        float: left;
        font-size: 16px;
        font-weight: 400;
        display: flex;
        align-items: center;
        color: #333333;

        img {
            margin-left: 14px;
            margin-right: 13px;
            width: 5px;
            height: 5px;
        }
    }

    .seach_box {
        position: absolute;
        top: 0px;
        bottom: 0px;
        margin: auto;
        left: 320px;
    }
}

.header_scoll .top_header {
    height: 56px;
}

.wx-code {
    margin: 0 auto 36px;
    width: 300px;
    height: 400px;
}

.wx-code-tips {
    text-align: center;
    font-weight: 600;
    line-height: 24px;

    .icon {
        color: #2bbc27;
        font-size: 20px;
        vertical-align: -2px;
    }
}

.login_box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1000;

    .login_content {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 15px;
        width: 600px;
        background: #ffffff;
        border-radius: 4px;

        .login_top {
            position: relative;
            font-size: 26px;
            line-height: 36px;
            color: #409eff;
            margin-bottom: 36px;

            i {
                position: absolute;
                top: 50%;
                right: 0;
                color: #666666;
                transform: translateY(-50%);
                cursor: pointer;

                &:hover {
                    color: #333333;
                    background: #eeeeee;
                }
            }
        }

        .login_from {
            padding: 0 50px;

            /deep/ .el-input__inner {
                height: 50px !important;
                line-height: 50px;
                padding-left: 46px;
            }

            /deep/ .el-input__icon {
                font-size: 28px;
                line-height: 50px;
            }
        }

        .automatic {
            padding: 10px 50px 30px;
            font-size: 14px;

            .forget_key {
                cursor: pointer;

                &:hover {
                    color: #409eff;
                }
            }
        }

        .phone_edictor {
            line-height: 40px;
            padding: 20px 50px;

            span {
                cursor: pointer;

                &:hover {
                    color: #409eff;
                }
            }

            .wxIconCon {
                display: inline-block;
                height: 47px;
            }

            .wxIcon {
                width: 47px;
            }
        }

        .footer {
            padding: 0 50px;
            text-align: center;

            span {
                color: #409eff;
                cursor: pointer;
            }
        }
    }
}

.log_btn {
    width: 100%;
    overflow: hidden;
    padding: 0 50px;
    cursor: pointer;

    .btn_it {
        font-family: "PingFang SC";
        height: 56px;
        width: 100%;
        overflow: hidden;
        font-size: 22px;
        letter-spacing: 5px;
        cursor: pointer;
    }
}

.login_arr {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 51px;
    margin: auto;
    display: flex;
    align-items: center;

    span,
    p {
        cursor: pointer;
        padding: 0 10px;
        font-size: 14px;
    }

    p {
        max-width: 160px;
    }
}

.log_box {
    display: inline-block;
    width: 89px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    background: #409eff;
    border-radius: 26px;
}

.log_box:hover {
    color: #fff !important;
}

.reg_box {
    margin-left: 26px;
}

.reg_box:hover {
    color: #409eff;
}

.login_in {
    @include flex-row();
    height: 100%;

    .avatar {
        width: 36px !important;
        height: 36px !important;
        border-radius: 50%;
        // margin-right: 15px;
    }

    .info_box {
        height: 100%;
        display: flex;
        align-items: center;

        > img {
            width: 34px;
            height: 34px;
        }

        p {
            padding: 0;
            color: #333333;
            font-size: 16px;
            margin-left: 12px;
            width: 95px;
        }

        ul {
            position: absolute;
            top: 90%;
            left: 93px;
            width: 147px;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            background: #ffffff;
            display: none;

            li {
                height: 31px;
                padding: 0px 25px;
                display: flex;
                align-items: center;

                img {
                    width: 20px;
                    height: 18px;
                }

                span {
                    padding: 0;
                    margin-left: 11px;
                }
            }

            .list_li {
                span {
                    width: 100%;
                    display: inline-block;
                    text-align: center;
                    margin: 0;
                    padding: 0;
                }
            }

            // li:hover {
            //   background-color: #409eff;
            //   color: #fff;
            //   span {
            //     color: #fff;
            //   }
            // }
            li:nth-of-type(1):hover {
                background-color: #409eff;
                color: #fff;

                img {
                    content: url("../../assets/images/personal2.png");
                }

                span {
                    color: #fff;
                }
            }

            li:nth-of-type(2):hover {
                background-color: #409eff;
                color: #fff;

                img {
                    content: url("../../assets/images/news2.png");
                }

                span {
                    color: #fff;
                }
            }

            li:nth-of-type(3):hover {
                background-color: #409eff;
                color: #fff;

                img {
                    content: url("../../assets/images/diamond2.png");
                }

                span {
                    color: #fff;
                }
            }

            li:nth-of-type(4):hover {
                background-color: #409eff;
                color: #fff;

                img {
                    content: url("../../assets/images/email2.png");
                }

                span {
                    color: #fff;
                }
            }

            li:nth-of-type(5):hover {
                background-color: #409eff;
                color: #fff;

                img {
                    content: url("../../assets/images/writer2.png");
                }

                span {
                    color: #fff;
                }
            }

            li:nth-of-type(6):hover {
                background-color: #409eff;
                color: #fff;

                span {
                    color: #fff;
                }
            }
        }
    }

    .info_box:hover {
        p {
            color: #409eff;
        }

        ul {
            display: block;
        }
    }

    .book_shelf {
        height: 100%;
        display: flex;
        align-items: center;
        width: 90px;

        img {
            width: 30px;
            height: 31px;
        }

        span {
            margin-left: 12px;
            text-align: center;
            color: #333333;
            font-family: Microsoft YaHei;
            font-size: 16px;
            padding: 0;
            margin-top: 4px;
        }
    }

    .book_shelf:hover {
        span {
            color: #409eff;
        }
    }
}

.code_num {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    /deep/ .el-button {
        margin-left: 10px;
    }
}
</style>
